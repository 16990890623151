@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Forty by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Spotlights */

	.spotlights {
		> section {
			&:after {
				content: '';
				display: block;
				clear: both;
			}

			> .image {
				float: left;
			}

			> .content {
				float: left;
			}
		}
	}

/* Tiles */

	.tiles {
		&:after {
			content: '';
			display: block;
			clear: both;
		}

		article {
			@include padding(8em, 4em);
			float: left;
			height: auto;
			max-height: none;
			min-height: 0;
		}
	}

/* Header */

	#header {
		.logo {
			position: absolute;
			top: 0;
			left: 0;
		}

		nav {
			position: absolute;
			top: 0;
			right: 0;
		}
	}

/* Banner */

	#banner {
		@include padding(6em, 0, (1em, 0, 0, 0));
		background-attachment: scroll;
		height: auto;
		max-height: none;
		min-height: 0;

		> .inner {
			.content {
				display: block;

				> * {
					margin-left: 0;
					margin: 0 0 _size(element-margin) 0;
				}
			}
		}

		&.major {
			height: auto;
			max-height: none;
			min-height: 0;
		}
	}

/* Contact */

	#contact {
		&:after {
			content: '';
			display: block;
			clear: both;
		}

		> .inner {
			> * {
				float: left;
			}
		}
	}

/* Menu */

	#menu {
		.inner {
			margin: 0 auto;
		}
	}